#A,
#E {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #e42256;
  --tw-gradient-to: rgb(228 34 86 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #9f002b;
}
#B,
#F {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #fdc74c;
  --tw-gradient-to: rgb(228 34 86 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #a87603;
}
#C,
#G {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #00b1b0;
  --tw-gradient-to: rgb(228 34 86 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #015e5e;
}
#D,
#H {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #ff8370;
  --tw-gradient-to: rgb(228 34 86 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #8f1b09;
}
