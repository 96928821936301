@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    @apply dark:bg-babbleBlack;
  }
}
@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham-Medium.woff2") format("woff2"),
    url("../public/fonts/Gotham-Medium.woff") format("woff"),
    url("../public/fonts/GothamMedium.ttf") format("truetype");
}
img {
  display: inline;
}
